import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxSpinnerModule } from "ngx-spinner";
import { AccordionModule } from "ngx-bootstrap/accordion";
import { NgxPaginationModule } from "ngx-pagination";
import { ModalModule } from "ngx-bootstrap/modal";
import { NgSelectModule } from "@ng-select/ng-select";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";

/** Modules */

import { AdminModule } from "./admin/admin.module";
import { AuthModule } from "./auth/auth.module";
import { SharedModule } from "./shared/shared.module";

/** services */
import { FirebaseQueryService } from "./core/api-service/firebase-query";
import { ApiServiceService } from "./core/api-service/api-service";
import { AlertNotification } from "./core/alertnotification/alertNotification";
import { LoaderService } from "../app/core/common-service/loader-service";

import { HttpErrorInterceptor } from "../app/core/interceptor-files/interceptor";

/** firebase */
import { firebaseConfig } from "../environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AdminModule,
    AuthModule,
    SharedModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    AccordionModule.forRoot(),
    RouterModule.forRoot([]),
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFirestoreModule,
    NgxPaginationModule,
    ModalModule.forRoot(),
    NgSelectModule,
  ],
  providers: [
    AlertNotification,
    ApiServiceService,
    FirebaseQueryService,
    { provide: HTTP_INTERCEPTORS, useClass: LoaderService, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
  exports: [AccordionModule],
  bootstrap: [AppComponent],
})
export class AppModule {}
