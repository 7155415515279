import { Component, OnDestroy, OnInit ,EventEmitter,Output} from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { BsModalRef } from "ngx-bootstrap/modal";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
@Component({
  selector: "app-pardna-modal",
  templateUrl: "./pardna-modal.component.html",
  styleUrls: ["./pardna-modal.component.scss"],
})
export class PardnaModalComponent implements OnInit /*OnDestroy*/ {

  registerForm: FormGroup;
  formSubmitted = false;
  pardna;
  @Output() modalResponse: EventEmitter<any> = new EventEmitter();
  constructor(
    public bsModalRef: BsModalRef,
    private formBuilder: FormBuilder,
    private httpClient: ApiServiceService,
    private firebaseservice: FirebaseQueryService,
    private alertnotification: AlertNotification
  ) { }
  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      name: [this.pardna?.name, [Validators.required]],
      amount: [this.pardna?.amount, [Validators.required]]
    });
  }


  close() {
    this.bsModalRef.hide();
  }
  async onSubmit() {  
    //this.formSubmitted = true;
    let calculation = (this.registerForm.value.amount * this.pardna.people) - (this.pardna.people * 2);
    const formattedAmount = new Intl.NumberFormat('en-GB', {
      style: 'currency',
      currency: 'GBP',
      minimumFractionDigits: 0,
      maximumFractionDigits: 0
    }).format(calculation);
    const updated = this.firebaseservice.updatePardnaDetail(this.pardna.documentId, this.registerForm.value, formattedAmount);
     this.modalResponse.emit(updated);
    if (updated) {
      this.bsModalRef.hide();
      this.alertnotification.successAlert(
        "Details updated successfully"
      );
    }
  }
}
