import { NgModule } from "@angular/core";
import { FormsModule } from '@angular/forms';
import { Routes, RouterModule } from "@angular/router";
import { AdminComponent } from "./admin.component";
import { DashboardComponent } from "./dashboard/dashboard.component";
import { PardnaComponent } from "./pardna/pardna.component";
import { ActivePardnasComponent } from "./active-pardnas/active-pardnas.component";
import { PardnaDetailsComponent } from "./pardna-details/pardna-details.component";
import { UserlistComponent } from "./userlist/userlist.component";
import { UserdetailComponent } from "./userdetail/userdetail.component";
import { PaymentsComponent } from "./payments/payments.component";
import { PaymentDetailsComponent } from "./payment-details/payment-details.component";
import { MessagePardnasComponent } from "./messages/message-pardnas.component"
const routes: Routes = [
  {
    path: "admin",
    component: AdminComponent,
    children: [
      { path: "dashboard", component: DashboardComponent },
      // { path :'pardna' , component : PardnaComponent },
      { path: "pardnaGroups", component: ActivePardnasComponent },
      { path: "message", component: MessagePardnasComponent },
      { path: "pardna/:id", component: PardnaDetailsComponent },
      // { path: "users", component: UserlistComponent },
      // { path: "payments", component: PaymentsComponent },
      // { path: "payment/:id", component: PaymentDetailsComponent },
      // { path: "user/:id", component: UserdetailComponent },
    ],
  },
];

@NgModule({
  imports: [
    FormsModule,
    RouterModule.forChild(routes)
  ],
  exports: [RouterModule, FormsModule],
})
export class AdminRoutingModule { }
