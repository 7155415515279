import { Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import * as firebase from "firebase";
// import * as admin from "firebase-admin";
import { Subject, BehaviorSubject } from "rxjs";
import { NgxSpinnerService } from "ngx-spinner";

const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: "root",
})
export class FirebaseQueryService {
  DB = firebase.firestore();

  constructor(
    private db: AngularFirestore,
    private loaderService: NgxSpinnerService
  ) { }

  createUser(email, password) {
    this.loaderService.show();
    return firebase.auth().createUserWithEmailAndPassword(email, password);
  }

  signInUser(email, password) {
    this.loaderService.show();
    return firebase.auth().signInWithEmailAndPassword(email, password);
  }

  currentUser() {
    this.loaderService.show();
    return firebase.auth().currentUser;
  }

  getPardna() {
    this.loaderService.show();
    return this.DB.collection("Pardnas").get();
  }

  getUsers() {
    this.loaderService.show();
    return this.DB.collection("users").get();
  }

  getPayments() {
    this.loaderService.show();
    return this.DB.collection("payments").where("deleted", "==", false).get();
  }

  async getNestedPayments() {
    const paymentsSnapshot = await this.DB.collection("payments").get();
    const payments = [];
    paymentsSnapshot.forEach((payment) => {
      payments.push({
        id: payment.id,
        ...payment.data(),
      });
    });
    console.log(payments);
  }

  getPardnaByRef(id) {
    this.loaderService.show();
    return this.DB.collection("Pardnas").doc(id).get();
  }

  getUserById(id) {
    this.loaderService.show();
    return this.DB.collection("users").where("userId", "==", id).get();
  }

  getUserByDoc(id) {
    this.loaderService.show();
    return this.DB.collection("users").doc(id).get();
  }

  getPardnaById(id) {
    console.log({ id });
    return this.DB.collection("Pardnas")
      .where("createdBy", "==", id)
      .get()
      .then(querySnapshot => {
        const validDocs = [];
        querySnapshot.forEach(doc => {
          const data = doc.data();

          if (data.deleted !== true) {
            validDocs.push(data);
          }
        });
        return validDocs;
      });
  }
  getPardnaByAllId(id) {
    return this.DB.collection("Pardnas")
      .where("createdBy", "==", id)
      .get()
  }

  updateUserSandbox(ref, sandbox) {
    return this.DB.collection("users")
      .doc(ref)
      .set({ sandbox: sandbox }, { merge: true });
  }

  getUserPardnaGroups(id) {
    return this.DB.collection("Pardnas")
      .where("group", "array-contains", id)
      .get();
  }

  updatePardnaSandbox(ref, sandbox) {
    return this.DB.collection("Pardnas")
      .doc(ref)
      .set({ sandbox: sandbox }, { merge: true });
  }

  getPreviousStartingPoint = async (startingPoint) => {
    try {
      const payments = [];
      let query = await this.DB.collection("payments")
        .orderBy("updatedAt", "asc")
        .startAt(startingPoint)
        .limit(20)
        .get();

      query.forEach((doc) => {
        payments.push({ id: doc.id, ...doc.data() });
      });
      return payments.splice(9);
    } catch (error) {
      console.log(123, error);
    }
  };

  async getPaginatedPayments(limit) {
    try {
      const payments = [];
      let query;
      if (limit) {
        query = this.DB.collection("payments")
          .where("deleted", "==", false)
          .orderBy("updatedAt", "desc")
          .limit(100);
      } else {
        query = this.DB.collection("payments")
          .where("deleted", "==", false)
          .orderBy("updatedAt", "desc");
      }
      const result = await query.get();
      result.forEach((doc) => {
        payments.push({ id: doc.id, ...doc.data() });
      });
      return { payments };
    } catch (error) {
      console.log(123, error);
    }
  }

  async getPardnaByArray() {
    try {
      const pardna = {};
      this.loaderService.show();
      const result = await this.DB.collection("Pardnas").get();

      result.forEach((doc) => {
        pardna[doc.id] = { id: doc.id, ...doc.data() };
      });

      return pardna;
    } catch (error) {
      console.log(123, error);
    }
  }

  async getUsersByArray() {
    try {
      const users = {};
      this.loaderService.show();
      const result = await this.DB.collection("users").get();

      result.forEach((doc) => {
        const data = doc.data();
        users[data.userId] = { id: doc.id, ...doc.data() };
      });

      return users;
    } catch (error) {
      console.log(123, error);
    }
  }

  async getPaymentLength() {
    this.loaderService.show();
    const coll = (
      await this.DB.collection("payments").where("deleted", "==", false).get()
    ).docs.length;
    return coll;
  }

  getPaymentByRef(id) {
    this.loaderService.show();
    return this.DB.collection("payments").doc(id).get();
  }
  getPaymentByUserId(id) {
    return this.DB.collection("payments").where("id", "==", id).get();
  }

  deletePayment(id) {
    this.loaderService.show();
    return this.DB.collection("payments").doc(id).update({ deleted: true });
  }
  async updatePardna(id, userId) {
    this.loaderService.show();
    const pardna = (await this.DB.collection("Pardnas").doc(id).get()).data();
    console.log(123, pardna);
    const payment_pending = [...pardna.payment_pending, userId];
    const payments = { ...pardna.payments };
    delete payments[userId];
    return this.DB.collection("Pardnas").doc(id).update({
      payment_pending,
      payments,
    });
  }
  async updateMember(documentId, Updatedmember) {
    this.loaderService.show();
    const existingData = (await (this.DB.collection("Pardnas").doc(documentId).get())).data();

    const updateData = { ...existingData, members: Updatedmember }
    // this.loaderService.hide();
    return this.DB.collection("Pardnas").doc(documentId).update(
      updateData
    )
  }

  async updatePardnaDetail(id, Detail, payout) {
    const { name, amount } = Detail;
    await this.DB.collection("Pardnas").doc(id).update({
      name: name,
      amount: parseInt(amount),
      payout: payout
    });
    return { success: true, data: { name, amount, payout } };
  }
  async updatePardnaById(id, status) {
    const deleted = status.deleted;
    try {
      await this.DB.collection("Pardnas").doc(id).update({
        deleted
      });
      console.log(`Pardna ${id} updated with deleted status: ${deleted}`);
      return { success: true };
    } catch (error) {
      console.error("Error updating pardna:", error);
      return { success: false, error: error };
    }
  }
  async sendMessage(documenId, chatMessage) {
    try {
      const parentDocRef = this.DB.collection('Pardnas').doc(documenId);
      const chatsCollectionRef = parentDocRef.collection('chats');
      await chatsCollectionRef.add(chatMessage);
    }
    catch (error) {
      console.log(error, "error");
    }
  }
  async fetchMessages(documentId, userID) {
    try {
      const chatsCollectionRef = this.DB.collection('Pardnas').doc(documentId).collection('chats').orderBy('time', 'desc');

      const snapshot = await chatsCollectionRef.get();

      if (snapshot.empty) {
        console.log('No documents found in the "chats" subcollection.');
        return [];
      }

      const chats = [];
      snapshot.forEach(doc => {
        chats.push({ id: doc.id, data: doc.data() });
      });

      return chats;
    }
    catch (error) {
      console.log(error, "error");
    }
  }
  async createAffiliate(affilateCreate) {
    return await this.DB.collection('affiliates').add(affilateCreate);
  }
  async getAffiliatebyId(id) {
    return await this.DB.collection("affiliates").where("userId", "==", id).get();
  }
}
