import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AngularFirestore } from "@angular/fire/firestore";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { NgxSpinnerService } from "ngx-spinner";
import * as firebase from "firebase";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit {
  registerForm: FormGroup;
  formSubmitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private alertnotification: AlertNotification,
    private apiServiceService: ApiServiceService,
    private loaderService: NgxSpinnerService,
    private firebaseservice: FirebaseQueryService
  ) { }

  ngOnInit(): void {
    this.registerForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
    });
  }
  async onSubmit() {
    this.formSubmitted = true;
    localStorage.removeItem("token");

    if (this.registerForm.invalid) {
      return;
    } else {
      console.log(this.registerForm.value);
      // User Sign In
      try {
        const response = await this.firebaseservice.signInUser(
          this.registerForm.value.email,
          this.registerForm.value.password
        );
        localStorage.setItem("User", JSON.stringify(response));
          this.firebaseservice
            .getUserById(response.user.uid)
            .then((querySnapshot) => {
              querySnapshot.forEach(async (doc) => {
                const data = doc.data();
                // verify user is affiliate or not
                if (data.affiliate == true) {
                  // Get Current User TokenID
                  firebase
                    .auth()
                    .currentUser.getIdToken()
                    .then((token) => {
                      console.log(token);
                      localStorage.setItem("token", JSON.stringify(token));
                      this.alertnotification.successAlert(
                        "Signed In Successfully"
                      );
                      this.router.navigate(["/admin/dashboard"]);
                    });
                } else {
                  const result = await this.firebaseservice.getAffiliatebyId(response.user.uid);
                  if (result.size > 0) {
                      this.alertnotification.errorAlert(
                        "We are still reviewing your application"
                      );
                      this.loaderService.hide();
                      localStorage.clear();
                    }else{
                      this.loaderService.hide();
                      this.router.navigate(["/affiliate-register"]);
                    }
                }

              });
            })

            .catch((error) => {
              console.log("Error getting documents: ", error);
            });
      } catch (error) {
        this.alertnotification.errorAlert(error);
        return;
      }

    }

  }

  errorMessageAlert(error) {
    this.alertnotification.errorAlert(error);
  }
}
