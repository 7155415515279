<div class="container-scroller">
  <div class="horizontal-menu">
    <app-header></app-header>
  </div>
  
  <div class="container-fluid page-body-wrapper p-0">
    <div class="main-panel">
      <app-nav-bar-top class="sidebar-content"></app-nav-bar-top>
      <div class="sidebar-content-wrapper">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
  <app-footer class="footer"></app-footer>
</div>
