<div class="site-header">
    <nav
      class="navbar navbar-expand-lg site-navbar navbar-light justify-content-between"
    >
      <a class="navbar-brand brand-logo">
        <img src="assets/logo/logo.jpg" alt="logo" />
      </a>
      <!-- <div class="website--name">
                Pardna Affiliate Dashboard
            </div> -->
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarText"
        aria-controls="navbarText"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="logoutButton">
        <a
          class="btn btn-outline-default btn-fw font-white"
          (click)="logoutUser()"
        >
          <i class="fa fa-lock"></i> Logout
        </a>
      </div>
    </nav>
</div>
