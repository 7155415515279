<div class="modal-header border-0 pb-0">
  <button type="button" class="close" (click)="close()">
    <span>&times;</span>
  </button>
</div>
<div class="modalBox">
  <form class="p-3 pb-4 text-center" [formGroup]="registerForm" (ngSubmit)="onSubmit()">
    <label><b>Pardna Title</b></label>
    <input
      type="text"
      class="form-control mb-3"
      name="name"
      formControlName="name"
    />
    <label><b>Monthly Hand</b></label>
    <input
      type="number"
      class="form-control mb-3"
      name="amount"
      formControlName="amount"
    />

    <div class="mt-3 d-flex align-items-center justify-content-center">
      <button type="submit" class="btn add-button">
        Save Pardna Details
      </button>
    </div>
  </form>
</div>
