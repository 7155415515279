import { Component, OnInit } from '@angular/core';
import { ApiServiceService } from 'src/app/core/api-service/api-service';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from 'ngx-spinner';
import { BsModalRef, BsModalService } from "ngx-bootstrap/modal";
import { PardnaModalComponent } from "../edit-pardnas-modal/pardna-modal.component";
import * as firebase from 'firebase';
@Component({
  selector: 'app-message-pardnas',
  templateUrl: './message-pardnas.component.html',
  styleUrls: ['./message-pardnas.component.scss']
})
export class MessagePardnasComponent implements OnInit {

  userList = [];
  userListCopy = [];
  sortvalue = ['createdAt', 'desc'];
  sort: string = 'asc';
  p: number = 1;
  pardnaDetails = false;
  pardnaDetailObject = null;
  activePardna: any;
  members = [];
  isEditMode = false
  selectedKeys = [];
  messages = [];
  currentUserId: string;
  newMessage: string = "";
  ActivepardnaId: string = "";
  statusArray = [
    { name: 'Started', id: "started" },
    { name: 'Ended', id: "ended" },
    { name: 'Pending', id: "pending" }
  ]
  sortDataArray: any;
  statusFilterArray = ['Live Pardnas', 'Test Pardnas'];
  modalRef: BsModalRef;
  constructor(
    private router: Router,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private apiServiceService: ApiServiceService,
    private modalService: BsModalService,

  ) { }

  ngOnInit(): void {

    this.loaderService.hide();
    this.getUserList();
    const currentUser = localStorage.getItem('User');
    const User = JSON.parse(currentUser)
    this.currentUserId = User.user.uid;
  }
  isCurrentUser(senderId: string): boolean {
    return senderId === this.currentUserId;
  }
  async getUserList() {
    this.userList = [];
    const currentUser = localStorage.getItem('User');
    const User = JSON.parse(currentUser)
    console.log(User.user.uid);
    const userId = User.user.uid;
    this.firebaseservice.getPardnaById(userId).then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const data = doc;
        if (!data.delete) {
          this.userList.push(data);
          this.userListCopy.push(data);
        }
      });
      this.handlePardnaClick(this.pardnaDetailObject ? this.userList.find(item => item.documentId === this.pardnaDetailObject.documentId) : this.userList[this.userList.length - 1]);
      this.fetchMessages(this.pardnaDetailObject ? this.userList.find(item => item.documentId === this.pardnaDetailObject.documentId) : this.userList[this.userList.length - 1]);
    }).catch((error) => {
      console.log("Error getting documents: ", error);
    });

  }

  clickPagination() {
    window.scroll(0, 0)
  }

  sortLisitng(value) {
    if (!this.sortvalue.includes(value)) {
      this.sortvalue = [value, this.sort];
    } else {
      this.sortvalue[1] == this.sort ? this.sortvalue = [value, 'desc'] : this.sortvalue = [value, 'asc'];
    }
  }

  convertDate(date) {
    return date._seconds ? moment.unix(date._seconds).format('MMM DD, YYYY') : '';
  }
  convertTimestampToTimeFirebase(timestamp) {
    if (timestamp && timestamp.toDate) {
      return moment(timestamp.toDate()).format('ddd, D MMM: HH:mm');
    }
    return '';
  }
  changeStatus(evt) {
    // console.log(evt)
    if (evt) {
      this.userList = this.sortDataArray.filter((x) => {
        return evt.id == x.status
      })
    } else {
      this.userList = this.sortDataArray;
    }
  }
  async handlePardnaClick(clickedPardna: any) {
    if (clickedPardna && clickedPardna.group) {

      this.members = [];
      this.activePardna = clickedPardna.name;
      const userIds = clickedPardna.group;

      this.pardnaDetails = true;
      this.pardnaDetailObject = clickedPardna;


      //  this.members = Array(clickedPardna.group.length);
      for (let i = 1; i <= clickedPardna.people; i++) {
        console.log("true");
        this.members[i] = { name: undefined, userId: undefined, photoUrl: 'assets/navigation/white.png', key: i }
      }

      userIds.forEach((userId: string) => {
        // Assuming firebaseservice.getUserById returns a Promise
        this.firebaseservice.getUserById(userId).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const userData = doc.data();

            // Assuming there is a field like 'photoUrl' in userData
            const photoUrl = userData.photoURL;
            // this.members.push({name:userData.firstName,userId:userId,photoUrl:photoUrl,key:this.pardnaDetailObject.members[userId]})
            this.members[this.pardnaDetailObject.members[userId]] = { name: userData.firstName, userId: userId, photoUrl: photoUrl, key: this.pardnaDetailObject.members[userId] };
            // Update the collection with the user ID and photo URL
          });
          this.selectedKeys = [];
          this.members.forEach((member) => {
            this.selectedKeys[member?.userId] = String(member?.key);
          });
          this.loaderService.hide();
        }).catch((error) => {
          console.log(`Error getting documents for user ${userId}:`, error);
        });
      });
    }
  }

  updatePardnaSandboxStatus(evt, id) {
    console.log(evt);
    let index = this.userList.findIndex(x => x.id === id);
    this.userList[index].sandbox = evt.target.checked;
    let indexCopy = this.userListCopy.findIndex(x => x.id === id);
    this.userListCopy[indexCopy].sandbox = evt.target.checked;
    this.firebaseservice.updatePardnaSandbox(id, evt.target.checked);
  }
  onChangestatusFilter(evt) {
    this.userList = this.userListCopy.map(x => x);
    this.p = 1;
    if (evt === this.statusFilterArray[0]) {
      // live users
      this.userList = this.userListCopy.filter((x) => {
        if (!x.sandbox) {
          return x;
        }
      })
    } else if (evt === this.statusFilterArray[1]) {
      // test users
      this.userList = this.userListCopy.filter((x) => {
        if (x.sandbox) {
          return x;
        }
      })
    }
  }
  handleSlotOrder() {
    this.isEditMode = true;

  }
  handleCancel() {
    this.isEditMode = false;
  }

  handleUpdateOrder() {
    console.log("=====", this.selectedKeys)
    for (const key in this.selectedKeys) {
      this.selectedKeys[key] = parseInt(this.selectedKeys[key])
      if (key === 'undefined') {
        delete this.selectedKeys[key];
      }
    }
    const selectedKeySet = new Set<string>();
    let hasDuplicates = false;

    for (const userId of Object.keys(this.selectedKeys)) {
      const selectedKey = this.selectedKeys[userId];
      if (selectedKeySet.has(selectedKey)) {

        hasDuplicates = true;
        break;
      }
      selectedKeySet.add(selectedKey);
    }

    if (hasDuplicates) {
      this.alertnotification.errorAlert(
        "2 or more Users cannot occupy same slot ,select different slot numbers for all users"
      );

    } else {
      const documentId = this.pardnaDetailObject.documentId;

      const Updatedmember = Object.assign({}, this.selectedKeys);
      // console.log(Updatedmember, "updated member");

      this.firebaseservice.updateMember(documentId, Updatedmember);
      this.getUserList();
      this.isEditMode = false;
    }
  }
  editPardna() {
    let initialState = {
      pardna: this.pardnaDetailObject
    };
    this.modalRef = this.modalService.show(PardnaModalComponent, { initialState });
    this.modalRef.content.modalResponse.subscribe((receivedPromise: Promise<any>) => {
      receivedPromise.then((receivedData) => {
        if (receivedData.success) {
          // console.log('Received data from modal:', receivedData);
          this.getUserList();
        }
        else {
          console.log("error on updated", receivedData.error)
        }
      });
    });

  }

  confirmAndDeletePardna(pardna) {
    const documenId = pardna;
    Swal.fire({
      title: 'Do you want to delete this Pardna?',
      showCancelButton: true,
      confirmButtonText: 'Delete',
    }).then((result) => {
      if (result.isConfirmed) {
        this.deletePardna(documenId);
      }
    });
  }
  deletePardna(documenId) {
    this.firebaseservice.updatePardnaById(documenId, { deleted: true }).then(() => {
      this.alertnotification.successAlert('Pardna marked as deleted successfully.');
      this.getUserList();
    }).catch(error => {
      this.alertnotification.errorAlert('Error marking pardna as deleted: ' + error.message);
    });
  }
  sendMessage() {
    if (this.newMessage.trim()) {
      const pardnaId = this.ActivepardnaId;
      const currentUser = localStorage.getItem('User');
      const User = JSON.parse(currentUser)
      const userId = User.user.uid;
      const name = User.user.displayName;
      const chatMessage = {
        message: this.newMessage,
        user: userId,
        name: name,
        time: firebase.firestore.FieldValue.serverTimestamp(),
      }
      this.firebaseservice.sendMessage(pardnaId, chatMessage).then(() => {
        this.fetchMessages(this.pardnaDetailObject);
        this.newMessage = ''
      }).catch(err => console.log(err));
    }
  }
  fetchMessages(pardna) {
    this.pardnaDetailObject = pardna;
    this.activePardna = pardna?.name;
    this.ActivepardnaId = pardna?.documentId;
    this.messages = [];
    // this.loaderService.show();
    const pardnaId = pardna?.documentId;
    const currentUser = localStorage.getItem('User');
    const User = JSON.parse(currentUser);
    const userId = User.user.uid;
    this.firebaseservice.fetchMessages(pardnaId, userId).then((allMessages) => {
      if (allMessages?.length > 0) {
        // console.log(allMessages);
        // this.loaderService.hide();
        this.messages = allMessages;
      } else {
        // this.loaderService.hide();
        console.log("No messages found or querySnapshot is undefined.");
      }
    })
  }
}
