import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { Subject, BehaviorSubject } from "rxjs";
import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
// import { NgxSpinnerService } from 'ngx-spinner';

// const cacheBuster$ = new Subject<void>();

@Injectable({
  providedIn: "root",
})
export class ApiServiceService {
  private dataSource = new BehaviorSubject("");
  private memberLength = new BehaviorSubject<number>(0);
  currentMessage = this.dataSource.asObservable();
  currentMemberLength = this.memberLength.asObservable();
  base_url = environment.baseUrl;
  pardnaMember:any

  httpOptions = {
    headers: new HttpHeaders({ "Content-Type": "application/json" }),
    // withCredentials: true
  };

  constructor(
    private http: HttpClient, // private loaderService: NgxSpinnerService
    private firebaseQuery:FirebaseQueryService
  ) { }

  getRequest(url: string, param = {}) {
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: param,
      // withCredentials: false
    };
    return this.http.get(`${this.base_url}/` + url, httpOptions);
  }
  getRequestAuth(url: string, param = {}) {
    const token = window.localStorage.getItem("token");
    const httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        authorization: `${token}`,
      }),
      params: param,
      // withCredentials: false
    };
    return this.http.get(`${this.base_url}/` + url, httpOptions);
  }

  postRequest(url, request_data) {
    return this.http.post(
      `${this.base_url}/` + url,
      request_data,
      this.httpOptions
    );
  }

  deleteRequest(url) {
    return this.http.delete(`${this.base_url}/` + url, this.httpOptions);
  }

  uploadRequest(url, form_data) {
    return this.http.post(`${this.base_url}/` + url, form_data);
  }

  putRequest(url, form_data) {
    return this.http.put(`${this.base_url}/` + url, form_data);
  }
}
