import { Component, OnInit } from '@angular/core';
import Swal from 'sweetalert2';
import { AlertNotification } from 'src/app/core/alertnotification/alertNotification';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private alertnotification : AlertNotification,
    private router : Router,
  ) { }

  ngOnInit(): void {
  }

  logoutUser(){
    Swal.fire({
      title: 'Do you want to logout ?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: 'blue',
      cancelButtonColor: 'red',
      confirmButtonText: 'Yes'
    })
    .then((result) => {
      if(result.value == true){
        localStorage.clear();
        this.alertnotification.successAlert('Logout Successfully');
        this.router.navigate(['']);
      }else{
        return;
      }
    });
  }

}
