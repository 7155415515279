import { Component, OnInit } from "@angular/core";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
// import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from "ngx-spinner";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";

@Component({
  selector: "app-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.scss"],
})
export class DashboardComponent implements OnInit {
  statsValue: any = {};
  payments: number = 0;
  userList = [];
  userListCount: number = 0;
  members = [];
  userListCopy = [];
  pardnaDetails = false;
  activePardna:any;
  pardnaDetailObject=null;
  constructor(
    private apiservice: ApiServiceService,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,
    private loaderService: NgxSpinnerService,
    private httpClient: ApiServiceService
  ) {}

  ngOnInit(): void {
    this.getUserlist();
    this.getPardnaGroup();
    this.getPardnasList();
    // this.getPaymentsList();
    this.getAllMongoPayments();
   
  }

  getPardnaGroup() {
    const currentUser = localStorage.getItem('User');
    const User = JSON.parse(currentUser);
    const userId = User.user.uid;
  
    this.firebaseservice.getPardnaByAllId(userId).then((querySnapshot) => {
      
      querySnapshot.forEach((doc) => {
        const data = doc.data();
        this.userList.push(data);
      });
      
      this.userListCount = this.userList.length;
      this.handlePardnaClick(this.userList[this.userList.length - 1]);
    }).catch((error) => {
      console.log("Error getting documents: ", error);
    });
  }
  handlePardnaClick(clickedPardna: any) {

    if (clickedPardna && clickedPardna.group) {
      
      this.members =[];
      this.activePardna = clickedPardna.name;
      const userIds = clickedPardna.group;
      console.log(userIds,"user");
      this.pardnaDetails =true;
      this.pardnaDetailObject = clickedPardna;
      console.log(this.pardnaDetailObject,"pardnaObject");
    
      //  this.members = Array(clickedPardna.group.length);
      for (let i = 1; i <= clickedPardna.people; i++) {
        console.log("true");
        this.members[i]={name:undefined,userId:undefined,photoUrl:'assets/navigation/white.png',key:i}
      }
      
      userIds.forEach((userId: string) => {
        // Assuming firebaseservice.getUserById returns a Promise
        this.firebaseservice.getUserById(userId).then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            const userData = doc.data();
           
            // Assuming there is a field like 'photoUrl' in userData
            const photoUrl = userData.photoURL;
            // this.members.push({name:userData.firstName,userId:userId,photoUrl:photoUrl,key:this.pardnaDetailObject.members[userId]})
            this.members[this.pardnaDetailObject.members[userId]]={name:userData.firstName,userId:userId,photoUrl:photoUrl,key:this.pardnaDetailObject.members[userId]};
            // Update the collection with the user ID and photo URL
          });
         
         
          this.loaderService.hide();
        }).catch((error) => {
          console.log(`Error getting documents for user ${userId}:`, error);
        });
      });
    }
  }
  getPardnasList() {
    try {
      var records1 = [];
      this.firebaseservice
        .getPardna()
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              records1.push(data);
            });
            this.statsValue["pardnas"] = records1.length;
            this.loaderService.hide();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
          }.bind(this)
        );
    } catch (error) {
      this.loaderService.hide();
      console.log(error);
    }
  }

  getUserlist() {
    try {
      var records1 = [];
    
      this.firebaseservice
        .getUsers()
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              records1.push(data);
            });
            this.statsValue["users"] = records1.length;
            this.loaderService.hide();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
          }.bind(this)
        );
    } catch (error) {
      this.loaderService.hide();
      console.log(error);
    }
  }

  getPaymentsList() {
    try {
      var payments = [];
      this.firebaseservice
        .getPayments()
        .then(
          function (querySnapshot) {
            querySnapshot.forEach(function (doc) {
              var data = doc.data();
              data["id"] = doc.id;
              payments.push(data);
            });
            this.statsValue["payments"] = payments.length;
            this.loaderService.hide();
          }.bind(this)
        )
        .catch(
          function (error) {
            this.loaderService.hide();
            console.log("Error getting documents: ", error);
          }.bind(this)
        );
    } catch (error) {
      this.loaderService.hide();
      console.log(error);
    }
  }

  async getAllMongoPayments() {
    try {
      const url = `paymentV3AllPayments?page=1&&limit=50`;
      const aa = await this.httpClient
        .getRequestAuth(url)
        .subscribe((data: any) => {
          this.payments = data.count;
        });
    } catch (error) {
      console.log(error);
    }
  }
}
