<div class="container-scroller">
  <div class="page-body-wrapper full-page-wrapper p-0">
    <div class="main-panel-login">
      <div class="site-header">
        <div class="container p-0 m-0 w-100">
          <nav
            class="navbar navbar-expand-lg site-navbar navbar-light justify-content-between"
          >
            <a class="navbar-brand brand-logo">
              <img src="assets/logo/logo.jpg" alt="logo" />
            </a>
          </nav>
        </div>
      </div>
      <div class="affiliateApplication" *ngIf="!approvalSubmitted">
        <div class="container">
          <div class="row">
            <div class="col-lg-6 col-md-10 mx-auto">
              <h3>Application to become a Pardna Affiliate</h3>
              <p>
                You can manage your pardna groups and earn monthly commission by
                bringing your existing pardna saving groups into the Pardna App
                platform.
              </p>
              <p class="mb-5">
                Register as an affiliate below.It takes 48 hours to review your application and
                will include a call with Pardna founder.
              </p>
              <div class="row">
                <form [formGroup]="registerAffiliate" (submit)="onSubmit()">
                  <div class="col-md-12">
                    <div class="form-group">
                      <label>Your Email</label>
                      <input
                        type="email"
                        class="form-control customWidth"
                        name="email"
                        formControlName="email"
                      />
                      <div
                        *ngIf="
                          formSubmitted &&
                          registerAffiliate.get('email').hasError('required')
                        "
                        class="has-error"
                      >
                        <span>required</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label>Confirm Email</label>
                      <input
                        type="email"
                        class="form-control customWidth"
                        name="confirmEmail"
                        formControlName="confirmEmail"
                      />
                      <div
                        *ngIf="
                          formSubmitted &&
                          registerAffiliate
                            .get('confirmEmail')
                            .hasError('required')
                        "
                        class="has-error"
                      >
                        <span>required</span>
                      </div>
                      <div
                        *ngIf="
                          formSubmitted &&
                          registerAffiliate
                            .get('confirmEmail')
                            .hasError('notEquivalent')
                        "
                        class="has-error"
                      >
                        <span>Email and Confirm Email must match</span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label
                        >How many people are in the pardna group/s you manage?
                        Please note you need to have more than 12 people to
                        become an affiliate.</label
                      >
                      <input
                        type="text"
                        class="form-control customWidth"
                        name="people"
                        formControlName="people"
                      />
                      <div
                        *ngIf="
                          formSubmitted &&
                          registerAffiliate.controls.people.errors
                        "
                        class="has-error"
                      >
                        <span
                          *ngIf="
                            formSubmitted &&
                            registerAffiliate.controls.people.errors
                          "
                          >required</span
                        >
                      </div>
                    </div>

                    <div class="form-group">
                      <label
                        >How are you managing the admin of your group/s? (e.g
                        WhatsApp, Excel, Email etc) </label
                      >
                      <input
                        type="text"
                        class="form-control customWidth"
                        name="adminGroup"
                        formControlName="adminGroup"
                      />
                      <div
                        *ngIf="
                          formSubmitted &&
                          registerAffiliate.controls.adminGroup.errors
                        "
                        class="has-error"
                      >
                        <span
                          *ngIf="
                            formSubmitted &&
                            registerAffiliate.controls.adminGroup.errors
                          "
                          >required</span
                        >
                      </div>
                    </div>
                    <div class="form-group">
                      <label
                        >How do you collect and distribute the pardna Hand and
                        Draw?</label
                      >
                      <input
                        type="text"
                        class="form-control customWidth"
                        name="distributePardna"
                        formControlName="distributePardna"
                      />
                      <div
                        *ngIf="
                          formSubmitted &&
                          registerAffiliate.controls.distributePardna.errors
                        "
                        class="has-error"
                      >
                        <span
                          *ngIf="
                            formSubmitted &&
                            registerAffiliate.controls.distributePardna.errors
                          "
                          >required</span
                        >
                      </div>
                    </div>
                    <div class="radioBtns">
                      <label
                        >Do you want to earn a commission from each person you
                        bring in?</label
                      >
                      <div class="d-flex">
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="commission"
                            id="flexRadioDefault1"
                            value="Yes"
                            formControlName="commission"
                          />
                          <label
                            class="form-check-label mr-4"
                            for="flexRadioDefault1"
                          >
                            Yes
                          </label>
                        </div>
                        <div class="form-check">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="commission"
                            id="flexRadioDefault2"
                            formControlName="commission"
                            checked
                            value="No"
                          />
                          <label
                            class="form-check-label"
                            for="flexRadioDefault2"
                          >
                            No
                          </label>
                        </div>
                      </div>
                    </div>
                    <div class="form-group">
                      <label>Any other comments?</label>
                      <input
                        type="text"
                        class="form-control"
                        name="comments"
                        formControlName="comments"
                      />
                      <div
                        *ngIf="
                          formSubmitted &&
                          registerAffiliate.controls.comments.errors
                        "
                        class="has-error"
                      >
                        <span
                          *ngIf="
                            formSubmitted &&
                            registerAffiliate.controls.comments.errors
                          "
                          >required</span
                        >
                      </div>
                    </div>
                    <div class="d-flex justify-content-center">
                      <button type="submit">Send Application</button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="affiliateRegisters" *ngIf="approvalSubmitted">
        <div class="container">
          <div class="pendingApproval">
            <div class="message">
              <h3>Applicaiton sent</h3>
              <p>Your Application has been sent successfully.</p>
              <p>
                We will review your application and get back to you within few
                days.
              </p>
            </div>
            <a routerLink="">
              <button class="add-buttonSlot">Return to login</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
