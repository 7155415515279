<nav class="bottom-navbar">
  <div class="container">
    <nav class="navbar navbar-expand-lg">
      <div class="collapse navbar-collapse" id="navbarText">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a class="nav-link pardna-btn">
              <span class="menu-title">Create Pardna</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/dashboard" >
              <span class="menu-title">Dashboard</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" routerLink="/admin/pardnaGroups" >
              <span class="menu-title">Pardna Groups</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <span class="menu-title">Messages</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <span class="menu-title">Notifications</span>
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link">
              <span class="menu-title">Account Settings</span>
            </a>
          </li>
        </ul>
      </div>
    </nav>
  </div>
</nav>

<div class="sidebar">
  <ul class="navbar-nav">
    <li class="nav-item">
      <a class="nav-link pardna-btn">
        <span class="menu-title">Create Pardna</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/admin/dashboard">
        <span class="menu-title" id="sidebar">Dashboard</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/admin/pardnaGroups">
        <span class="menu-title" id="sidebar">Pardna Groups</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="/admin/message">
        <span class="menu-title" id="sidebar">Messages</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link">
        <span class="menu-title" id="sidebar">Notifications</span>
      </a>
    </li>
    <li class="nav-item">
      <a class="nav-link">
        <span class="menu-title" id="sidebar">Account Settings</span>
      </a>
    </li>
  </ul>
</div>