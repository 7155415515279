import { Component, OnInit } from "@angular/core";
import { ApiServiceService } from "src/app/core/api-service/api-service";
import { AlertNotification } from "src/app/core/alertnotification/alertNotification";
// import { FirebaseQueryService } from 'src/app/core/api-service/firebase-query';
import { NgxSpinnerService } from "ngx-spinner";
import { FirebaseQueryService } from "src/app/core/api-service/firebase-query";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-dashboard",
  templateUrl: "./affilate-register.component.html",
  styleUrls: ["./affilate-register.component.scss"],
})
export class affiliateRegisterComponent implements OnInit {
  registerAffiliate: FormGroup;
  formSubmitted = false;
  approvalSubmitted = false;
  email;
  constructor(
    private formBuilder: FormBuilder,
    private alertnotification: AlertNotification,
    private firebaseservice: FirebaseQueryService,

  ) { }

  ngOnInit(): void {
    const currentUser = localStorage.getItem('User');
    const User = JSON.parse(currentUser);
    this.email = User.user.email;
    this.registerAffiliate = this.formBuilder.group({
      email: [this.email, [Validators.required]],
      confirmEmail: [this.email, [Validators.required]],
      people: [null, [Validators.required]],
      adminGroup: [null, [Validators.required]],
      distributePardna: [null, [Validators.required]],
      commission: ['No', [Validators.required]],
      comments: [null, [Validators.required]],
    }, { validators: this.checkIfMatchingEmail('email', 'confirmEmail') });
  }
  checkIfMatchingEmail(email: string, confirmEmail: string) {
    return (group: FormGroup) => {
      const emailInput = group.controls[email];
      const confirmEmailInput = group.controls[confirmEmail];
      if (emailInput.value !== confirmEmailInput.value) {
        confirmEmailInput.setErrors({ notEquivalent: true });
      }
      else {
        confirmEmailInput.value ? confirmEmailInput.setErrors(null) : confirmEmailInput.setErrors({ required: true })
      }
    };
  }
  async onSubmit() {
    this.formSubmitted = true;
    if (this.registerAffiliate.invalid) {
      return;
    }
    else {
      const currentUser = localStorage.getItem('User');
      const User = JSON.parse(currentUser);
      const userId = User?.user?.uid;
      const name = User?.user?.displayName;

      try {
        const affiliateData = {
          application: {
            'email?': this.registerAffiliate.value.email,
            'How many people are in the pardna group/s you manage? Please note you need to have more than 12 people to become an affiliate.?': this.registerAffiliate.value.people,
            'How are you managing the admin of your group/s? (e.g WhatsApp, Excel, Email etc) ?': this.registerAffiliate.value.adminGroup,
            'How do you collect and distribute the pardna Hand and Draw?': this.registerAffiliate.value.distributePardna,
            'Do you want to earn a commission from each person you bring in?': this.registerAffiliate.value.commission,
            'Any other comments?': this.registerAffiliate.value.comments,
          },
          approved: false,
          userId,
          name,
          date: new Date().toISOString(),
        }

        const result = await this.firebaseservice.createAffiliate(affiliateData);
        this.alertnotification.successAlert(
          "Affiliate Application sent for review"

        );
        this.approvalSubmitted = true;

        //localStorage.clear(); 
        //console.log(this.registerAffiliate.value, "register affilated");
      }
      catch (error) {
        this.alertnotification.errorAlert(
          "Something went Wrong when registering an affilate"
        );
      }

    }
  }
}
