<!-- <div class="content-wrapper">
  <div class="row">
    <div class="col-lg-12 grid-margin stretch-card">
      <div class="card mrr-card">
        <div class="card-body">
          <div class="row">
            <div class="col-sm-6">
              <div class="card--title-row">
                <h4 class="card-title">Active Pardnas</h4>
                <span class="totalnmbr">Total {{ userList?.length }}</span>
                <div class="filter--user text-left mr-2">
                  <ng-select
                    [searchable]="false"
                    [clearable]="true"
                    bindLabel="statusName"
                    bindValue="statusName"
                    [items]="statusFilterArray"
                    (change)="onChangestatusFilter($event)"
                    placeholder="Show Pardnas"
                  >
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="col-sm-6">
              <div class="card--title-button">
                <ng-select
                  style="width: 228px"
                  [items]="statusArray"
                  bindLabel="name"
                  bindValue="id"
                  placeholder="Filter By Status"
                  (change)="changeStatus($event)"
                ></ng-select>
              </div>
            </div>
          </div>
          <div class="table-responsive pt-3">
            <table class="table user-data-table table-striped">
              <thead>
                <tr>
                  <th style="cursor: pointer" (click)="sortLisitng('name')">
                    Name
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'name',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'name'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('people')">
                    Members
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'people',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'people'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('amount')">
                    Amount
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'amount',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'amount'
                      }"
                    ></i>
                  </th>
                  <th
                    style="cursor: pointer"
                    (click)="sortLisitng('createdAt')"
                  >
                    Created<i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'createdAt',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'createdAt'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('months')">
                    Months
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'months',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'months'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('payout')">
                    Payout
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'payout',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'payout'
                      }"
                    ></i>
                  </th>
                  <th style="cursor: pointer" (click)="sortLisitng('status')">
                    Status
                    <i
                      class="fa"
                      [ngClass]="{
                        'fa-arrow-down':
                          sortvalue[1] == 'asc' && sortvalue[0] == 'status',
                        'fa-arrow-up':
                          sortvalue[1] == 'desc' && sortvalue[0] == 'status'
                      }"
                    ></i>
                  </th>
                  <th>Action</th>
                  <th>Test Pardna</th>
                </tr>
              </thead>
              <tbody>
                <ng-template
                  *ngIf="userList?.length != 0"
                  ngFor
                  let-pardna
                  [ngForOf]="
                    userList
                      | SortingFilter : sortvalue
                      | paginate : { itemsPerPage: 50, currentPage: p }
                  "
                  let-i="index"
                >
                  <tr>
                    <td>
                      <a
                        class="pardna--name"
                        routerLink="/admin/pardna/{{ pardna.id }}"
                        >{{ pardna.name }}</a
                      >
                    </td>
                    <td>{{ pardna.people }}</td>
                    <td>{{ pardna.amount ? "£" + pardna.amount : "" }}</td>
                    <td>{{ convertDate(pardna.createdAt) || "" }}</td>
                    <td>{{ pardna.months }}</td>
                    <td>{{ pardna.payout }}</td>
                    <td [ngClass]="pardna.status">{{ pardna.status }}</td>
                    <td>
                      <a
                        class="btn btn-outline-brand"
                        routerLink="/admin/pardna/{{ pardna.id }}"
                        >VIEW</a
                      >
                    </td>
                    <td>
                      <label class="switch">
                        <input
                          type="checkbox"
                          (click)="updatePardnaSandboxStatus($event, pardna.id)"
                          [checked]="pardna.sandbox"
                        />
                        <span class="slider round"></span>
                      </label>
                    </td>
                  </tr>
                </ng-template>
                <tr *ngIf="userList?.length == 0">
                  <td colspan="8" class="text-center">No documents found !</td>
                </tr>
              </tbody>
            </table>
            <div
              *ngIf="userList?.length > 0"
              class="pagination mt-4 float-right"
            >
              <pagination-controls
                (click)="clickPagination()"
                (pageChange)="p = $event"
                directionLinks="true"
                previousLabel="Previous"
                nextLabel="Next"
              ></pagination-controls>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="inner-content">
  <div class="pardnas-content">
    <div class="pardnas-groups">
      <div class="pardnas-title">
        <h4 class="card-title fs-24">Pardna Groups</h4>
        </div>
      <ng-template
    
        *ngIf="userList?.length != 0"
        ngFor
        let-pardna
        [ngForOf]="
          userList
            | SortingFilter : sortvalue
            | paginate : { itemsPerPage: 50, currentPage: p }
        "
        let-i="index"
      >
      <!-- <button *ngIf="pardna.group.length < pardna.people" (click)="confirmAndDeletePardna(pardna)">
        Delete
      </button> -->
        <a class="pardna--name" (click)="handlePardnaClick(pardna)">
          <div
            class="pardnas-group"
            [class.active]="activePardna === pardna.name"
          >
            <div>
              <p class="title">
                {{ pardna.name }}
              </p>
              <p class="status" [ngClass]="pardna.status">
                {{ pardna.status }}
              </p>
            </div>
            <div>
              <img src="assets/navigation/arrow-right.png" />
            </div>
          </div>
        </a>
      </ng-template>
    </div>
    <div class="pardna-block">
      <div class="groups-title">
        <button class="delete-btn" *ngIf="pardnaDetailObject?.group?.length < pardnaDetailObject?.people"  (click)="confirmAndDeletePardna(pardnaDetailObject?.documentId)">
       
          Delete
        </button>
        <button class="add-button" *ngIf="!isEditMode" (click)="handleSlotOrder()">
          Edit Slot Order 
        </button>
        <div class="isEditMode" *ngIf="isEditMode">
          <button class="add-buttonCancel" (click)="handleCancel()">Cancel</button>
          <button class="add-buttonSlot" (click)="handleUpdateOrder()">
            Save Slot Order
          </button>
        </div>
      </div>
      <div class="pardnas-detail">
        <div *ngIf="!pardnaDetails">
          Select a group from the list on the left to display details
        </div>
        <div *ngIf="pardnaDetails">
          <!-- {{pardnaDetailObject.name}} -->
          <div class="details">
            <div class="group-imgs">
              <ng-container *ngIf="members">
                <div
                  *ngFor="let member of members | keyvalue; let i = index"
                  class="person"
                >
                  <div class="text-center position-relative">
                    <select
                      *ngIf="isEditMode"
                      class="memberDropDwon"
                      [(ngModel)]="selectedKeys[member.value.userId]"
                    >
                      <option
                        *ngFor="let option of members | keyvalue"
                        [value]="option.value.key"
                      >
                        {{ option.value.key }}
                      </option>
                    </select>
                    <p>{{ member.value.key }}</p>
                
                    <div class="tick" *ngIf="pardnaDetailObject && pardnaDetailObject.payments && pardnaDetailObject.payments[member.value.userId]">
                      <img src="/assets/navigation/tick.png" alt="Money Icon">
                    </div>
                    <div class="money" *ngIf="pardnaDetailObject && pardnaDetailObject.paid && pardnaDetailObject.paid[member.value.userId]">
                      <img src="/assets/navigation/money.png" alt="Tick Icon">
                    </div>
                    <img
                      [src]="
                        member.value.photoUrl
                          ? member.value.photoUrl
                          : 'assets/navigation/user.jpeg'
                      "
                      alt="img"
                      class="borderColor"
                    />
                    <p>{{ member.value.name || "." }}</p>
                  </div>
                </div>
              </ng-container>
  
              <!-- <div class="person">
              <p>2</p>
            <img src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="img">
            <p>Steve</p>
            </div>
            <div class="person">
              <p>3</p>
            <img src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="img">
            <p>Steve</p>
            </div>
            <div class="person">
              <p>4</p>
            <img src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="img">
            <p>Steve</p>
            </div>
            <div class="person">
              <p>5</p>
            <img src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="img">
            <p>Steve</p>
            </div>
            <div class="person">
              <p>6</p>
            <img src="https://t3.ftcdn.net/jpg/02/43/12/34/360_F_243123463_zTooub557xEWABDLk0jJklDyLSGl2jrr.jpg" alt="img">
            <p>Steve</p>
            </div> -->
            </div>
            <div class="draw-amount">
             <div>
              <h3>{{ pardnaDetailObject.payout }}</h3>
              <h5>Your Draw amount</h5>
              <p>This payout includes platform fee</p>
             </div>
             <a class="editPardna" (click)="editPardna()">Edit Details</a>
            </div>
            <div class="draw-content">
              <img src="assets/navigation/1.png" />
              <div class="content">
                <h3>Your Draw Date</h3>
                <h4>
                  {{
                    pardnaDetailObject.payOutDate?.toDate() | date : "MMMM d, y"
                  }}
                </h4>
              </div>
            </div>
            <div class="draw-content">
              <img src="assets/navigation/coin.jpeg" />
              <div class="content">
                <h3>Monthly Hand</h3>
                <h4>£{{ pardnaDetailObject.amount }}</h4>
              </div>
            </div>
            <div class="draw-content">
              <img src="assets/navigation/cal.jpeg" />
              <div class="content">
                <h3>Length of Commitment</h3>
                <h4>{{ pardnaDetailObject.months }}</h4>
              </div>
            </div>
            <div class="draw-content">
              <img src="assets/navigation/user.jpeg" />
              <div class="content">
                <h3>Number of Slots</h3>
                <h4>{{ pardnaDetailObject.people }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
